import {
    Box,
    Button,
    Grid,
    GridItem,
    Text,
    Stack,
    Flex,
    Icon,
    Image,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Center,
    Link,
    Container,
    Divider
} from '@chakra-ui/react';
import {
    ChevronRightIcon,
} from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import GooglePlaySVG from '../Assets/SVG/google_svg';
import AppStoreSVG from '../Assets/SVG/appstore_svg';
import { ReactComponent as QRCode } from '../Assets/SVG/qrcode.svg';
import logo from '../Assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCalendar, faLocationPin } from '@fortawesome/free-solid-svg-icons'
import { slides as tabs } from '../Helpers/images.ts'
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"







const First = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} >

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {/* <Text fontFamily='GilroyBlack' fontSize={'12'}>Download Now</Text> */}
                        <Image src={logo} h={['25px', '25px', '20px']} />

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image src='https://do1pg20gb8r9m.cloudfront.net/siteV2/Proximy_Doorway_full.jpg' mb={6} rounded={'2xl'} />
                        <Center flexDirection={'column'}>
                            <Grid templateColumns='repeat(2, 1fr)' gap={4} >
                                <Box py={6}>
                                    <Text color={'blackAlpha.700'} textAlign={'left'} fontSize={'16'} fontFamily='GilroyBlack' pt={'10px'} pb={'5px'}>
                                        Download Now
                                    </Text>

                                    <Text color={'blackAlpha.700'} textAlign={'left'} fontSize={'12'} fontFamily='GilroySemi' pt={'5px'} pb={'20px'}>
                                        Scan this QR Code to get redirected to the app on your phone
                                    </Text>
                                </Box>
                                <Center>
                                    <QRCode />
                                </Center>
                            </Grid>

                            <Grid templateColumns='repeat(2, 1fr)' gap={4} pt={'30px'} pb={'50px'} w={'full'}>


                                <Button
                                    as={Link}
                                    rounded={'xl'}
                                    href={'https://play.google.com/store/apps/details?id=com.proximy.proximyapp'}
                                    target='_blank'
                                    // mr={4}

                                    p={8}

                                    bg={'#508CF7'}

                                    _active={{
                                        bg: 'buttons.selected'
                                    }}
                                    _hover={{
                                        bg: '#2a6ce2',
                                    }}
                                >
                                    <GooglePlaySVG />
                                </Button>

                                <Button
                                    as={Link}
                                    rounded={'xl'}
                                    href={'https://apps.apple.com/us/app/proximy/id1563596886'}
                                    target='_blank'
                                    // mr={4}
                                    p={8}
                                    bg={'#508CF7'}

                                    _hover={{
                                        bg: '#2a6ce2',
                                    }}
                                >
                                    <AppStoreSVG />
                                </Button>


                            </Grid>


                        </Center>
                    </ModalBody>


                </ModalContent>

            </Modal>
            <Box py={['30px', '160', '160']} pl={['30px', '160', '160']} pr={['30px', '50px', '50px']}>
                <Text color={'#508CF7'} fontSize={'18'} fontFamily='GilroySemi' pb={'10px'}>
                    Let’s Hangout
                </Text>
                <Box fontSize={'50'} fontFamily='GilroyBlack' lineHeight={'55px'} letterSpacing={'-0.04em'} pb={'5px'}>
                    Exploring and connecting with your community made simple.
                </Box>
                <Box fontSize={'18'} fontFamily='GilroySemi' color={'blackAlpha.800'} pb={'15px'} pr={'50px'}>
                    Create unforgettable experiences with like minded people.
                </Box>
                <Button
                    role={'group'}
                    display={'block'}
                    px={[4]}
                    onClick={onOpen}
                    h={['55px', '55px', '45px']}
                    rounded={'xl'}
                    bg={'#508CF7'}
                    _active={{
                        bg: 'buttons.selected'
                    }}
                    _hover={{
                        bg: '#2a6ce2',
                    }}
                >
                    <Stack direction={'row'} align={'center'}>
                        <Flex
                            transition={'all .3s ease'}

                            opacity={0}
                            px={1}
                            _groupHover={{ opacity: '100%' }}
                            justify={'flex-end'}
                            align={'center'}
                            flex={1}>

                            {/* <FontAwesomeIcon color={'white'} width={10} height={10} icon={faBriefcase} /> */}

                        </Flex>


                        <Box>
                            <Text
                                transition={'all .3s ease'}
                                color='white'
                                fontWeight={700}
                                fontSize='14px'
                                fontFamily={'GilroyBold'}

                            >
                                Get the app
                            </Text>
                        </Box>

                        <Flex
                            transition={'all .3s ease'}
                            transform={'translateX(-10px)'}
                            opacity={0}

                            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                            justify={'flex-end'}
                            align={'center'}
                            flex={1}>
                            <Icon color={'white'} w={5} h={5} as={ChevronRightIcon} />

                        </Flex>
                    </Stack>

                </Button>


            </Box>

        </>
    );
}



const Second = () => {
    // const [selectedTab, setSelectedTab] = useState(tabs[0]);
    // var items = ['set1', 'set2', 'set3'];
    // var [indexItem, setIndex] = useState(0);

    const [videoloaded, setVideoloaded] = useState(false);

    // const iframe_container = {
    //     left: 0,
    //     width: "100%",
    //     height: 500,
    //     position: "relative"
    // }

    // const iframe = {
    //     top: 0,
    //     left: 0,
    //     width: "100%",
    //     height: "100%",
    //     position: "absolute",
    //     border: 0
    // }
    // const callOnTimer = () => {

    //     indexItem < 2 ? setTimeout(() => {
    //         setIndex(indexItem + 1);
    //     }, 5000) : setIndex(0);;

    // }

    // useEffect(() => {
    //     callOnTimer();
    // });


    return (
        <>
            {/* 
            {videoloaded ? null :


                <Skeleton
                    h={["230px", "230px", "230px", "430px"]}
                    w="100%"
                    bg="black">
                    <div>contents wrapped</div>
                </Skeleton>
            } */}
            <Box h={['auto', 'auto', '37em']} w="100%" borderRadius={'10px'} px={['20px', '20px', '150px']} >
                {/* <Flex> */}

                <video style={videoloaded ? { objectFit: "contain", borderRadius: "12px", border: "5px solid #508CF7" } : { display: 'none' }} onLoadedDataCapture={() => (setVideoloaded(true), console.log('Video Done!'))} autoPlay muted playsInline loop  >
                    <source src="https://do1pg20gb8r9m.cloudfront.net/siteV2/videos/landingHere.mp4" type="video/mp4" />
                </video>
                {/* </Flex> */}
                <Box border={'1px solid #ddddde'} borderRadius={'5px'} mt={'5px'} w={['100%', '83%', '83%']} p={'5px'}>

                    <Text fontSize={'11px'} fontFamily='GilroyBlack' >
                        Taken from
                    </Text>
                    {/* <Divider /> */}
                    <Grid templateColumns='30% 70%' gap={2}>
                        <Image mt={'5px'} objectFit={'cover'} src='https://d3t9f6zdo5vugp.cloudfront.net/gallery/IMG_6416.jpg' borderRadius={'5px'} h='70px' w={'full'} />
                        <Flex flexDirection={'column'}>
                            <Text fontSize={'14px'} fontFamily='GilroyBlack'  >
                                Apple Pie at Sahil’s
                            </Text>
                            <Flex alignItems={'baseline'} mt={'2px'}>
                                <FontAwesomeIcon icon={faCalendar} color={'#5b5b5b'} fontSize="11px" />

                                <Text fontSize={'11px'} color={'#5b5b5b'} fontFamily='GilroySemi' ml={'5px'}>
                                    Was on April 16, 2022
                                </Text>
                            </Flex>
                            <Flex alignItems={'baseline'}>
                                <FontAwesomeIcon icon={faUser} color={'#5b5b5b'} fontSize="11px" />

                                <Text fontSize={'11px'} color={'#5b5b5b'} fontFamily='GilroySemi' ml={'5px'}>
                                    8 people went
                                </Text>
                            </Flex>
                            <Flex alignItems={'baseline'}>
                                <FontAwesomeIcon icon={faLocationPin} color={'#5b5b5b'} fontSize="11px" />

                                <Text fontSize={'11px'} color={'#5b5b5b'} fontFamily='GilroySemi' ml={'5px'}>
                                    Vancouver, BC
                                </Text>
                            </Flex>

                        </Flex>

                    </Grid>

                </Box>
            </Box>
            {/* <AnimatePresence exitBeforeEnter>
                <motion.div
                    key={selectedTab ? selectedTab[items[indexItem]] : "empty"}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >

                    <Grid templateColumns='repeat(3, 1fr)' gap={6} px={'10px'} >
                        <Image h={'320'} w={'full'} border={'1px solid #508CF7'} rounded={'2xl'} objectFit={'cover'} src={selectedTab[items[indexItem]][0]} />
                        <Image h={'320'} w={'full'} border={'1px solid #508CF7'} rounded={'2xl'} objectFit={'cover'} src={selectedTab[items[indexItem]][1]} />

                        <Image h={'320'} w={'full'} border={'1px solid #508CF7'} rounded={'2xl'} objectFit={'cover'} src={selectedTab[items[indexItem]][2]} />

                        <Image pos={'relative'} border={'1px solid #508CF7'} w={'full'} right={'25px'} h={'320'} rounded={'2xl'} objectFit={'cover'} src={selectedTab[items[indexItem]][3]} />
                        <Image pos={'relative'} border={'1px solid #508CF7'} w={'full'} right={'25px'} h={'320'} rounded={'2xl'} objectFit={'cover'} src={selectedTab[items[indexItem]][4]} />

                        <Image pos={'relative'} border={'1px solid #508CF7'} w={'full'} right={'25px'} h={'320'} rounded={'2xl'} objectFit={'cover'} src={selectedTab[items[indexItem]][5]} />


                    </Grid>
                </motion.div>

            </AnimatePresence> */}

        </>
    )
}



const Hero = () => {
    return (
        <>

            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} pt={'85px'} gap={6}>
                <GridItem w='100%' >
                    <First />
                </GridItem>
                <GridItem w='100%' py={'20px'}  >
                    <Second />
                </GridItem>

            </Grid>
        </>
    );
};

export default Hero; 