import {
    Box,
    Flex,
    Stack,
    Button,
    Image,
    Link as ChakraLink,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Text,
    ModalFooter,
    Center,
    useDisclosure,
    Container
} from '@chakra-ui/react';

import logo from '../Assets/logo.png';
import React from 'react';
import ModalCall from './modalCall';






export default function WithSubnavigation() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

    return (
        <>
            <ModalCall isOpen={isOpenModal} onClose={onCloseModal} onOpen={onOpenModal} />

            <Modal onClose={onClose} size={'5xl'} isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader color={'#508CF7'} fontFamily='GilroyBlack'>  Community Guidelines</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody fontFamily='GilroyBold'>
                        <Container maxW={'6xl'} mt={'20px'}>
                            “Proximy prioritizes positivity, kindness, and diversity. Equity of opportunity is incredibly important to us. We’re helping people come together in hopes of solving a global problem and ending social isolation.”  - Proximy Team
                            <br />
                            <br />

                            Our community guidelines help us anchor our work and ensure that there is always an intention for us to come back to. Failure to follow these guidelines as a Host or Participant may result in losing access to our platform indefinitely.
                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Respect:
                            </Text>
                            Diversity is at the root of our team and platform’s values. Proximy prioritizes the respect of people’s interests, beliefs, culture, and opinions. We will take a strong stance against any form of racism, hate speech, and abusive behavior. We encourage all of our community users to report those who do not follow these guidelines.
                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Speech:
                            </Text>
                            Words have weight. They also have an impact. Please be empathetic and respectful with others on the platform and be mindful of how you talk to people, how you want to be spoken to, and the language you engage with. Your speech should bring people together, not spread us further apart.
                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Scamming and Spamming:
                            </Text>
                            Scamming or spamming will not be tolerated. This includes: financial solicitation, creating intentionally false events or groups, forcibly pushing a personal agenda or company products, repetitive comments, any physical or emotional scams as well as misleading photos, links or posts designed to trick users.
                            <br />

                            Content on Proximy is meant for community involvement and creating opportunities for its users to get engaged locally. The content is not meant and should never be used maliciously against other users in an attempt to stalk, hurt, defame or negatively impact others lives or intentionally cause harm.

                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Sexual Conduct:
                            </Text>
                            We value your freedom of expression, however we do not permit nudity or sexually explicit content anywhere on the platform. Proximy is not a dating app and all in-person meetings are expected to be non-sexual.
                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Impersonation:
                            </Text>
                            The best person that you can be is yourself, which is why your Proximy profile should accurately represent you. Please do not catfish other members or pretend to be someone you are not. That’s not cool.

                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Reporting:
                            </Text>
                            See it? Don’t like it? Report it. Anything that violates our guidelines or feels like an inappropriate use of Proximy can and should be reported. You may send all your reports to info@proximy.ca.

                            <br />
                            <br />
                            <Text color={'#508CF7'}>
                                Feedback:

                            </Text>

                            We love to hear it! We want to keep getting bigger and better, and we can only do that if you show us how. If you have any feedback on how we can improve, please get in touch with our team at info@proximy.ca.


                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <nav>
                <Stack w="full" pos={'fixed'} backgroundColor={'white'} zIndex={'100'}>
                    <Center h={'80px'} justifyContent={'space-between'} px={[4, 4, 24]}>




                        <Image src={logo} h={['25px', '25px', '34px']} />
                        <Flex>
                            {/* <Image src={logo} h={['auto', 'auto', '34px']} /> */}

                            <Button
                                mr={'20px'}
                                as={'a'}
                                fontSize={'sm'}
                                fontWeight={400}
                                onClick={onOpen}
                                variant={'link'}
                                display={{ base: 'none', md: 'inline-flex' }}
                            >
                                Community Guidelines
                            </Button>
                            <Button
                                onClick={onOpenModal}

                                display={{ base: 'block', md: 'inline-flex' }}
                                fontSize={'sm'}
                                fontWeight={600}
                                // borderRadius='8px'
                                rounded={'xl'}
                                color={'white'}
                                bg={'#508CF7'}

                                _active={{
                                    bg: 'buttons.selected'
                                }}
                                _hover={{
                                    bg: '#2a6ce2',
                                }}

                            >
                                Feedback
                            </Button>

                        </Flex>
                        {/* <Image src={logo} h={['auto', 'auto', '32px']} /> */}





                    </Center >
                </Stack>
            </nav>
        </>
    );
}






