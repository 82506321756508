import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import PartnerForm from '../Widgets/feedbackForm'



const ModalDesign = (props) => {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} size={'xl'} mx={'15px !important'}>
            <ModalOverlay />
            <ModalContent  >
                <ModalHeader >Tell us what you think</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={8} px={[2, 2, 8]}>
                    <PartnerForm closeModal={props.onClose} />

                </ModalBody>


            </ModalContent>
        </Modal >
    )
}


const ModalCall = (props) => {
    return (
        <>
            <ModalDesign isOpen={props.isOpen} onClose={props.onClose} onOpen={props.onOpen} />
        </>
    )
}

export default ModalCall;