import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDjk9ZGgzQYSBzVV5aS2RSUa6bGvyzqacs",
    authDomain: "proximy-5b73d.firebaseapp.com",
    databaseURL: "https://proximy-5b73d.firebaseio.com",
    projectId: "proximy-5b73d",
    storageBucket: "proximy-5b73d.appspot.com",
    messagingSenderId: "715502013786",
    appId: "1:715502013786:web:ac9f426bd0176b0a033763",
    measurementId: "G-79GC0JKCS4"
};

const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();

export { app, db };
