import {
    Box,
    Text,
    Button,
    Stack,
    ButtonGroup,
    CardFooter,
    Divider,
    Image,
    useDisclosure,
    TagLeftIcon,
    Icon,
    Flex,
    TagLabel,
    Tag,
    HStack,
    Container,
    Grid,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Center,
    Link
} from '@chakra-ui/react';
import {
    ChevronRightIcon,
    AddIcon
} from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faStar, faTicket } from '@fortawesome/free-solid-svg-icons'
import { db } from '../Services/firebase.js';
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"
import GooglePlaySVG from '../Assets/SVG/google_svg';
import AppStoreSVG from '../Assets/SVG/appstore_svg';
import { ReactComponent as QRCode } from '../Assets/SVG/qrcode.svg';
import logo from '../Assets/logo.png';



const timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var time = date + ' ' + month;
    return time;
}



const HangoutCard = (props) => {
    const memberLeft = props.maxMembers - props.members.length;



    return (
        <>
            <motion.div whileHover={{ y: 5, scale: 1.02 }} whileTap={{ scale: 1 }}>
                <Box pt={'5px'} key={props.index}  >
                    <Image src={props.image} overflow={'hidden'} h={'280px'} w={['full', 'full', '280px']} mb={'10px'} objectFit={'cover'} borderRadius={'10px'} />
                    <HStack spacing='5px'>
                        <Tag size={'md'} key={'md'} variant='subtle' >
                            {/* <TagLeftIcon boxSize='12px' as={AddIcon} /> */}
                            <TagLeftIcon>
                                <FontAwesomeIcon icon={faTicket} color={'#508bf6'} />
                            </TagLeftIcon>
                            <TagLabel fontSize={'12px'} color={'#508bf6'}>{memberLeft} people left</TagLabel>
                        </Tag>
                        <Tag size={'md'} key={'md'} variant='subtle'  >
                            {/* <TagLeftIcon boxSize='12px' as={AddIcon} /> */}
                            <TagLeftIcon>
                                <FontAwesomeIcon icon={faUser} color={'#508bf6'} />
                            </TagLeftIcon>
                            {
                                props.online ?
                                    <TagLabel fontSize={'12px'} color={'#508bf6'}>Online</TagLabel> :
                                    <TagLabel fontSize={'12px'} color={'#508bf6'}>In Person</TagLabel>
                            }

                        </Tag>
                    </HStack>
                    <Text pt={'6px'} fontFamily='GilroyBlack' fontSize={'16px'}>
                        {props.name}
                    </Text>
                    <Text fontFamily='GilroyRegular' fontSize={'14px'}>
                        {timeConverter(props.startTime['seconds'])}
                    </Text>


                </Box>
            </motion.div>
        </>
    )
}



const HangoutList = () => {

    const [hangouts, setHangouts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Fetchdata();
        console.log('i fire once');
    }, []);




    // window.addEventListener('load', () => {
    // });

    const Fetchdata = () => {
        var currentTime = new Date();
        currentTime.setMonth(currentTime.getMonth() - 1)
        console.log(Date.now());


        db.collection('events')
            .where('startTime', '>=', currentTime)
            .orderBy('startTime', "desc")
            .limit(5)
            .get().then((querySnapshot) => {
                querySnapshot.forEach(element => {
                    var data = element.data();

                    setHangouts(arr => [...arr, data]);
                });
            });
        setLoading(false);
    }
    const { isOpen, onOpen, onClose } = useDisclosure()



    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {/* <Text fontFamily='GilroyBlack' fontSize={'12'}>Download Now</Text> */}
                        <Image src={logo} h={['25px', '25px', '20px']} />

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image src='https://do1pg20gb8r9m.cloudfront.net/siteV2/Proximy_Doorway_full.jpg' mb={6} rounded={'2xl'} />
                        <Center flexDirection={'column'}>
                            <Grid templateColumns='repeat(2, 1fr)' gap={4} >
                                <Box py={6}>
                                    <Text color={'blackAlpha.700'} textAlign={'left'} fontSize={'16'} fontFamily='GilroyBlack' pt={'10px'} pb={'5px'}>
                                        Download Now
                                    </Text>

                                    <Text color={'blackAlpha.700'} textAlign={'left'} fontSize={'12'} fontFamily='GilroySemi' pt={'5px'} pb={'20px'}>
                                        Scan this QR Code to get redirected to the app on your phone
                                    </Text>
                                </Box>
                                <Center>
                                    <QRCode />
                                </Center>
                            </Grid>

                            <Grid templateColumns='repeat(2, 1fr)' gap={4} pt={'30px'} pb={'50px'} w={'full'}>


                                <Button
                                    as={Link}
                                    rounded={'xl'}
                                    href={'https://play.google.com/store/apps/details?id=com.proximy.proximyapp'}
                                    target='_blank'
                                    // mr={4}

                                    p={8}

                                    bg={'#508CF7'}

                                    _active={{
                                        bg: 'buttons.selected'
                                    }}
                                    _hover={{
                                        bg: '#2a6ce2',
                                    }}
                                >
                                    <GooglePlaySVG />
                                </Button>

                                <Button
                                    as={Link}
                                    rounded={'xl'}
                                    href={'https://apps.apple.com/us/app/proximy/id1563596886'}
                                    target='_blank'
                                    // mr={4}
                                    p={8}
                                    bg={'#508CF7'}

                                    _hover={{
                                        bg: '#2a6ce2',
                                    }}
                                >
                                    <AppStoreSVG />
                                </Button>


                            </Grid>


                        </Center>
                    </ModalBody>


                </ModalContent>

            </Modal>
            <Container maxW='7xl' mt={['60px', '110px', '110px']}>

                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontFamily='GilroyBlack' fontSize={'22px'} lineHeight={'26px'}>
                        Hangouts Going On
                    </Text>
                    <Button
                        role={'group'}
                        display={'block'}
                        px={[4]}
                        onClick={onOpen}
                        h={['55px', '55px', '45px']}
                        rounded={'xl'}
                        bg={'white'}
                    // _active={{
                    //     bg: 'buttons.selected'
                    // }}
                    // _hover={{
                    //     bg: '#2a6ce2',
                    // }}
                    >
                        <Stack direction={'row'} align={'center'}>
                            <Flex
                                transition={'all .3s ease'}

                                opacity={0}
                                px={1}
                                _groupHover={{ opacity: '100%' }}
                                justify={'flex-end'}
                                align={'center'}
                                flex={1}>

                                {/* <FontAwesomeIcon color={'white'} width={10} height={10} icon={faBriefcase} /> */}

                            </Flex>


                            <Box>
                                <Text
                                    transition={'all .3s ease'}
                                    color='#508CF7'
                                    fontWeight={700}
                                    fontSize='14px'
                                    fontFamily={'GilroyBold'}

                                >
                                    View more on app
                                </Text>
                            </Box>

                            <Flex
                                transition={'all .3s ease'}
                                transform={'translateX(-10px)'}
                                opacity={0}

                                _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                                justify={'flex-end'}
                                align={'center'}
                                flex={1}>
                                <Icon color={'#508CF7'} w={5} h={5} as={ChevronRightIcon} />

                            </Flex>
                        </Stack>

                    </Button>
                </Flex>

                {loading ?

                    <Box h={'370px'} mt={'10px'}>
                        <Center>
                            <Spinner size='xl' color='#508CF7' />
                        </Center>
                    </Box>
                    :

                    <Grid
                        templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(5, 1fr)',
                        ]}
                        gap={{ base: '4', sm: '4', md: '4' }} mt='15px'>
                        {
                            hangouts.map((hangout, index) => (
                                <HangoutCard
                                    key={index}
                                    image={hangout['imageUrl']}
                                    name={hangout['name']}
                                    startTime={hangout['startTime']}
                                    online={hangout['online']}
                                    maxMembers={hangout['maxMembers']}
                                    members={hangout['members']}

                                />
                            ))
                        }

                    </Grid>
                }

            </Container>

        </>
    );
};

export default HangoutList;