import logo from './logo.svg';
import './App.css';
import WithSubnavigation from './Widgets/navbar.js';
import Hero from './Widgets/hero';
import { Box } from '@chakra-ui/react';
import Second from './Widgets/second';
import Third from './Widgets/third';
import Fourth from './Widgets/fourth';
import Footer from './Widgets/footer.js';
import HangoutList from './Widgets/hangouts_list';
import Hangout from './Widgets/hangouts';
import Hosts from './Widgets/hosts';
import { Routes, Route } from "react-router-dom";




const Home = () => {
  return (
    <>
      <Hero />

      <HangoutList />


      <Second />

      <Hangout />

      <Hosts />

      <Third />


    </>
  )
}

function App() {
  return (
    <>
      <WithSubnavigation />


      <Routes>

        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/community-guidelines" element={ } /> */}


      </Routes>






      <Footer />





    </>
  );
}

export default App;
