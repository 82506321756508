import {
    Box,
    Text,
    Button,
    Stack,
    Flex,
    Container,
    Grid,
    Image,
    Center,
    GridItem,
    Avatar
} from '@chakra-ui/react';
import {
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { slides as tabs } from '../Helpers/images.ts'
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"




const First = () => {

    return (
        <>
            <Container maxW='7xl' mt={'20px'}>
                <Grid
                    pt={12}
                    templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(2, 1fr)',
                    ]}
                    gap={{ base: '8', sm: '8', md: '8' }}>
                    <Center h={'480px'}>
                        <Box pt={['60px']} pr={['0px', '90px', '90px']}>

                            <Box fontSize={'45'} color={'#508CF7'} fontFamily='GilroyBlack' lineHeight={'55px'} letterSpacing={'-0.04em'} >
                                What is Proximy?
                            </Box>
                            <Text color={'black'} fontSize={'18'} fontFamily='GilroySemi' >
                                Proximy is an online network that connects people to local experts in-person and online. Our mission is twofold: create one-on-one and in-person connections, while allowing people to earn an income doing what they love.
                            </Text>
                            <Grid
                                pt={12}
                                templateColumns={[
                                    'repeat(1, 1fr)',
                                    'repeat(2, 1fr)',
                                    'repeat(2, 1fr)',
                                ]}
                                gap={{ base: '8', sm: '8', md: '8' }}>
                                <GridItem>
                                    <Avatar name='Connection' border={'2px solid black'} src='https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg?auto=compress&cs=tinysrgb&w=200&h=200&dpr=2' />

                                    <Text pt={'10px'} pb={'2px'} fontFamily='GilroyBlack' fontSize={'18'} >Create Connections</Text>

                                    <Text fontSize={'14'} fontFamily='GilroySemi' >We do this to curb social isolation and give humans what they crave the most community & connection</Text>


                                </GridItem>

                                <GridItem>

                                    <Avatar name='Personal' border={'2px solid black'} src='https://images.pexels.com/photos/747964/pexels-photo-747964.jpeg?auto=compress&cs=tinysrgb&w=200&h=200&dpr=2' />

                                    <Text pt={'10px'} pb={'2px'} fontFamily='GilroyBlack' fontSize={'18'} >Personalized Experience</Text>

                                    <Text fontSize={'14'} fontFamily='GilroySemi' >Unlike it’s competitors, Proximy is a no-cost service that provides a highly personalized experience </Text>


                                </GridItem>





                            </Grid>

                            {/* //put like a little clipping sayin sahils apple pie or some shit */}
                        </Box>
                    </Center>
                    <Center h={['380px', '480px', '480px']} alignItems={'flex-end'}>
                        <Image src={"https://do1pg20gb8r9m.cloudfront.net/siteV2/OnlineHangout.jpg"} borderRadius={'12px'} />
                    </Center>

                </Grid>
            </Container>
        </>
    );
}

const SecondTile = () => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    var [indexItem, setIndex] = useState(0);

    const callOnTimer = () => {

        indexItem < 5 ? setTimeout(() => {

            setIndex(indexItem + 1);
        }, 8000) : setIndex(0);;

    }

    useEffect(() => {
        callOnTimer();
        console.log(selectedTab['set1'][0]);
    });

    return (
        <>
            {/* <Image h={'700px'} objectFit={'cover'} src={'https://images.unsplash.com/photo-1539635278303-d4002c07eae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'} /> */}

            <Container maxW='7xl' pt={'90px'}>

                <AnimatePresence exitBeforeEnter>
                    <motion.div
                        key={selectedTab ? selectedTab['set1'][indexItem] : "empty"}
                        initial={{ y: -10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 10, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Image h={'450px'} w={'full'} borderRadius={'20px'} objectFit={'cover'} src={selectedTab['set1'][indexItem]} />

                    </motion.div>

                </AnimatePresence>
            </Container>
        </>
    );
}


// backgroundColor={'#508CF7'}
const Second = () => {
    return (
        <>


            <Box w={'100%'} >

                <First />
                <SecondTile />




            </Box>


        </>
    );
};

export default Second;