import {
    Box,
    Text,
    Button,
    Stack,
    Flex,
    Icon,
    Grid,
    Image,
    Center,
    Container,
    Badge,
    GridItem
} from '@chakra-ui/react';
import {
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { slides as tabs } from '../Helpers/images.ts'
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"




const First = () => {

    return (
        <>
            <Center h={'400px'}>
                <Box py={['145']}  >

                    <Box p={['0px', '35px', '35px']}>

                        <Badge p={2} my={4} colorScheme='green' rounded={'md'}>Announcement</Badge>

                        <Text color={'blackAlpha.700'} fontSize={'18'} fontFamily='GilroyBoldItalic' w={['full', 'full', '80%']}>
                            “Our main focus is to help provide solopreneurs and freelance workers with a better opportunity to make a living, while also looking to improve mental health by addressing social isolation through community connections.”
                        </Text>


                        <Text color={'blackAlpha.700'} fontSize={'18'} fontFamily='GilroyBlack' mt={'20px'}>
                            A message from our CEO, Arpan Kandola
                        </Text>


                    </Box>
                </Box>
            </Center>
        </>
    );
}

const SecondTile = () => {

    return (
        <>
            {/* <Image h={'700px'} objectFit={'cover'} src={'https://images.unsplash.com/photo-1539635278303-d4002c07eae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'} /> */}

            <Image borderBottomRightRadius={'3xl'} mr={['0px', '0px', '35px']} h={['auto', 'auto', '400px']} w={'full'} objectFit={'cover'} src={'https://do1pg20gb8r9m.cloudfront.net/siteV2/ArpanKandola-scaled-removebg-preview.png'} />


        </>
    );
}


const Third = () => {
    return (
        <>

            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: false }}
            >
                <Box w={'100%'} mt={'140px'} backgroundColor={'#f6f6f6'} >
                    <Container maxW='7xl' pt={'80px'}>

                        <Grid templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)', '70% 30%']} p={['0px', '0px', '50px']}>
                            <First />
                            <SecondTile />


                        </Grid>
                    </Container>

                </Box>

            </motion.div>

        </>
    );
};

export default Third;