import {
    Box,
    Text,
    Button,
    Stack,
    Flex,
    Container,
    Grid,
    Image,
    Icon,
    GridItem,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    useDisclosure,
    ModalBody,
    Center,
    Link
} from '@chakra-ui/react';
import {
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { slides as tabs } from '../Helpers/images.ts'
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'
import { motion, AnimatePresence, isValidMotionProp } from "framer-motion"
import GooglePlaySVG from '../Assets/SVG/google_svg';
import AppStoreSVG from '../Assets/SVG/appstore_svg';
import { ReactComponent as QRCode } from '../Assets/SVG/qrcode.svg';
import logo from '../Assets/logo.png';



const First = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()


    return (
        <>

            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {/* <Text fontFamily='GilroyBlack' fontSize={'12'}>Download Now</Text> */}
                        <Image src={logo} h={['25px', '25px', '20px']} />

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image src='https://do1pg20gb8r9m.cloudfront.net/siteV2/Proximy_Doorway_full.jpg' mb={6} rounded={'2xl'} />
                        <Center flexDirection={'column'}>
                            <Grid templateColumns='repeat(2, 1fr)' gap={4} >
                                <Box py={6}>
                                    <Text color={'blackAlpha.700'} textAlign={'left'} fontSize={'16'} fontFamily='GilroyBlack' pt={'10px'} pb={'5px'}>
                                        Download Now
                                    </Text>

                                    <Text color={'blackAlpha.700'} textAlign={'left'} fontSize={'12'} fontFamily='GilroySemi' pt={'5px'} pb={'20px'}>
                                        Scan this QR Code to get redirected to the app on your phone
                                    </Text>
                                </Box>
                                <Center>
                                    <QRCode />
                                </Center>
                            </Grid>

                            <Grid templateColumns='repeat(2, 1fr)' gap={4} pt={'30px'} pb={'50px'} w={'full'}>


                                <Button
                                    as={Link}
                                    rounded={'xl'}
                                    href={'https://play.google.com/store/apps/details?id=com.proximy.proximyapp'}
                                    target='_blank'
                                    // mr={4}

                                    p={8}

                                    bg={'#508CF7'}

                                    _active={{
                                        bg: 'buttons.selected'
                                    }}
                                    _hover={{
                                        bg: '#2a6ce2',
                                    }}
                                >
                                    <GooglePlaySVG />
                                </Button>

                                <Button
                                    as={Link}
                                    rounded={'xl'}
                                    href={'https://apps.apple.com/us/app/proximy/id1563596886'}
                                    target='_blank'
                                    // mr={4}
                                    p={8}
                                    bg={'#508CF7'}

                                    _hover={{
                                        bg: '#2a6ce2',
                                    }}
                                >
                                    <AppStoreSVG />
                                </Button>


                            </Grid>


                        </Center>
                    </ModalBody>


                </ModalContent>

            </Modal>

            <Box >
                <Grid pt={12}

                    templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(4, 1fr)',
                        'repeat(4, 1fr)',
                    ]}
                    gap={{ base: '8', sm: '8', md: '8' }}
                >


                    <Box>
                        <Image src={"https://do1pg20gb8r9m.cloudfront.net/siteV2/sec1.png"} borderRadius={'12px'} />
                        <Text color={'#508CF7'} fontSize={'18'} ml={'5px'} fontFamily='GilroyBlack' mt={'10px'} >
                            1
                        </Text>
                        <Text color={'black'} fontSize={'16'} ml={'5px'} fontFamily='GilroyBold' mt={'5px'} >
                            Search for nearby Hangouts and select one.
                        </Text>
                    </Box>
                    <Box>
                        <Image src={"https://do1pg20gb8r9m.cloudfront.net/siteV2/sec2.png"} borderRadius={'12px'} />

                        <Text color={'#508CF7'} fontSize={'18'} ml={'5px'} fontFamily='GilroyBlack' mt={'10px'} >
                            2
                        </Text>
                        <Text color={'black'} fontSize={'16'} ml={'5px'} fontFamily='GilroyBold' mt={'5px'}>
                            Join the one(s) you vibe with and get ready to meet like-minded people.
                        </Text>
                    </Box>
                    <Box >
                        <Image src={"https://do1pg20gb8r9m.cloudfront.net/siteV2/sec3.png"} borderRadius={'12px'} />
                        <Text color={'#508CF7'} fontSize={'18'} ml={'5px'} fontFamily='GilroyBlack' mt={'10px'} >
                            3
                        </Text>
                        <Text color={'black'} fontSize={'16'} ml={'5px'} fontFamily='GilroyBold' mt={'5px'} >
                            Build meaningful social connections with other attendees once the Hangout is wrapped.
                        </Text>

                    </Box>
                    <GridItem >
                        <Flex flexDirection={'column'} alignItems={'flex-start'}>
                            <Box mb={'20px'} fontSize={'55'} color={'#508CF7'} fontFamily='GilroyBlack' lineHeight={'55px'} letterSpacing={'-0.04em'} >
                                Ready to Hangout?
                            </Box>
                            <Button
                                role={'group'}
                                display={'block'}
                                px={[4]}
                                onClick={onOpen}
                                h={['55px', '55px', '45px']}
                                rounded={'xl'}
                                bg={'#e4e8ee'}

                            >
                                <Stack direction={'row'} align={'center'}>
                                    <Flex
                                        transition={'all .3s ease'}

                                        opacity={0}
                                        px={1}
                                        _groupHover={{ opacity: '100%' }}
                                        justify={'flex-end'}
                                        align={'center'}
                                        flex={1}>

                                        {/* <FontAwesomeIcon color={'white'} width={10} height={10} icon={faBriefcase} /> */}

                                    </Flex>


                                    <Box>
                                        <Text
                                            transition={'all .3s ease'}
                                            color='#508CF7'
                                            fontWeight={700}
                                            fontSize='14px'
                                            fontFamily={'GilroyBold'}

                                        >
                                            Get the app
                                        </Text>
                                    </Box>

                                    <Flex
                                        transition={'all .3s ease'}
                                        transform={'translateX(-10px)'}
                                        opacity={0}

                                        _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                                        justify={'flex-end'}
                                        align={'center'}
                                        flex={1}>
                                        <Icon color={'#508CF7'} w={5} h={5} as={ChevronRightIcon} />

                                    </Flex>
                                </Stack>

                            </Button>
                        </Flex>
                    </GridItem>



                </Grid>
                {/* //put like a little clipping sayin sahils apple pie or some shit */}
            </Box>



        </>
    );
}



const Second = () => {

    return (
        <>


            <Grid pt={12}
                templateColumns={['repeat(1, 1fr)', '25% 50%', '25% 50%']}
                alignItems={'center'}
                py={0}
                my={0}
            >


                <Box fontSize={'55'} color={'#508CF7'} fontFamily='GilroyBlack' lineHeight={'55px'} letterSpacing={'-0.04em'} >
                    What are Hangouts?
                </Box>
                <Text color={'black'} fontSize={'18'} fontFamily='GilroySemi' mt={['10px', '0px', '0px']} >
                    Simply put, Hangouts are in-person or online events, activities, or classes hosted by local experts. The possibilities are endless when dreaming up your own Hangout. Hangouts happen in 3 simple steps:
                </Text>


            </Grid>
            {/* //put like a little clipping sayin sahils apple pie or some shit */}



        </>
    );
}






const Hangout = () => {

    return (
        <>


            <Container maxW='7xl' pt={'100px'}>
                <Second />

                <First />





            </Container>


        </>
    );
};

export default Hangout;