import React, { useState } from "react";
import { Box, Container, Grid, Text, GridItem, Input, Button, useToast, Textarea, Select } from "@chakra-ui/react";
import emailjs from '@emailjs/browser';



export default function PartnerForm(props) {


    const toast = useToast();
    const initialValues = {
        personName: '',
        feedback: '',
        email: '',
    }


    const [values, setValues] = useState(initialValues);
    const [isLoading, setLoading] = useState(false);
    const [isEnabled, setEnabled] = useState(true);





    const handleChange = e => {
        var { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        })
        if (values.personName && values.feedback && values.email) {
            setEnabled(false);
        }

    }



    const sendEmail = async (e) => {
        e.preventDefault();
        setLoading(true)

        await emailjs.send('service_bd1ir4w', 'template_dw1b8ll', { from_email: values.email, from_name: values.personName, feedback: values.feedback }, 'DWGEV0wZAVb6LUWJp')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });



        toast({
            title: "Thank you.",
            description: "Thank you for contacting us.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        setLoading(false);

        setValues({ ...initialValues });
        props.closeModal();
        e.target.reset();
    }




    return (
        <>
            <Box py={"30px"}>
                <Container>
                    <form onSubmit={sendEmail} className="form">
                        <Grid templateColumns="repeat(2, 1fr)" gap={"35px 20px"} >
                            <GridItem>
                                <Text pb={2} fontSize={'14px'} fontWeight={'800'}>Name</Text>
                                <Input variant="filled" value={values.personName} onChange={handleChange} type="text" name="personName" placeholder="Ex. John Doe" py={4} _focus={{ borderColor: "#4f8df7", backgroundColor: "transparent" }} />
                            </GridItem>

                            <GridItem    >
                                <Text pb={2} fontSize={'14px'} fontWeight={'800'}>Email</Text>

                                <Input variant="filled" value={values.email} onChange={handleChange} type="email" name="email" placeholder="john.doe@acme.com" py={4} _focus={{ borderColor: "#4f8df7", backgroundColor: "transparent" }} />
                            </GridItem>

                            <GridItem colSpan={2}   >
                                <Text pb={2} fontSize={'14px'} fontWeight={'800'}>Feedback</Text>

                                <Textarea variant="filled" value={values.feedback} onChange={handleChange} type="text" name="feedback" py={4} _focus={{ borderColor: "#4f8df7", backgroundColor: "transparent" }} placeholder="Please add skateboarding as an interest..." />
                            </GridItem>


                            <GridItem colSpan={2} pt={4}  >

                                <Button
                                    isLoading={isLoading}
                                    disabled={isEnabled}
                                    type="submit"
                                    bg="#71a6ff"
                                    _hover={{ bg: "#4f8df7" }}
                                    _focusWithin={{ bg: "#4f8df7" }}
                                    size="md"
                                    color="white"

                                >
                                    Submit
                                </Button>
                            </GridItem>
                        </Grid>
                    </form>
                </Container>

            </Box>


        </>
    )
}